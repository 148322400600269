import { Message, Segment, colorWithOpacity, spacingFn } from '@britishcouncil/react-extro-ui';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import nppTheme from '../styles/nppTheme';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.sm};
`;

export const PaymentMethodLogo = styled(Segment)`

  img {
    max-height: ${spacingFn(5.25)};
    max-width: ${spacingFn(12.5)};
    height: ${spacingFn(5.25)};
  }

  box-shadow: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.indigo};
`;

export const InternalPaymentMethodLogo = styled(PaymentMethodLogo)`
  img {
    max-height: ${spacingFn(4.5)};
    max-width: ${spacingFn(4.5)};
    height: ${spacingFn(4.5)};
  }
`;

export const PaymentMethodSegment = styled(Segment)<{ disabled: boolean }>`
  align-items: center;
  justify-content: center;
  min-height: ${spacingFn(9)};
  padding: 10px;

  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: not-allowed;
        opacity: 0.3;
      `;
    }
  }};

  :focus {
    border: 2px solid ${colorWithOpacity('darkBlue', 0.5)};
  }
`;

export const InternalPaymentMethodText = styled.div`
  font-family: ${nppTheme.typography.bcSans};
  color: ${({ theme }) => theme.colors.indigo};
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize.desktop.small};
  gap: 1px;
`;

export const InternalPaymentMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacingFn(0.25)};
`;

export const PaymentMethodList = styled.div`
  > [class^='col'] {
    padding: 0 ${({ theme }) => theme.spacing.sm};
    margin-top: ${({ theme }) => theme.spacing.lg};
  }
`;

export const PaymentMethodChangerMessage = styled(Message)`
  margin-top: ${spacingFn(6)};
`;
