import { Segment, spacingFn } from '@britishcouncil/react-extro-ui';
import styled from '@emotion/styled';

export const Root = styled(Segment)`
  padding: 0;
  gap: 0;
  background: transparent;
  border-radius: ${spacingFn(1.5)};

  > .ex-segment {
    padding: ${({ theme }) => theme.spacing.xl} ${({ theme }) => theme.spacing.md};
    gap: ${({ theme }) => theme.spacing.lg};
    ${({ theme }) => theme.elevation[0]};

    ${({ theme }) => theme.mediaQueries.md} {
      padding: ${({ theme }) => theme.spacing.xl} ${({ theme }) => theme.spacing.lg};
    }

    border-radius: ${spacingFn(1.5)};

    :first-of-type:not(:last-of-type) {
      border-end-start-radius: 0;
      border-end-end-radius: 0;
    }

    :last-of-type:not(:first-of-type) {
      border-start-start-radius: 0;
      border-start-end-radius: 0;
    }

    :not(:first-of-type):not(:last-of-type) {
      border-radius: 0;
    }
  }
`;

export const FullWidthSegment = styled(Segment)`
  && {
    padding: 0;
  }
`;

export const Title = styled.div`
  * > {
    margin-bottom: unset;
  }
`;

