import React, { useEffect, useMemo } from 'react';
import * as S from './PaymentMethodChanger.styles';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import {
  PaymentMethodChangeStatus,
  useErrorSummaryContext,
  usePaymentMethodContext,
} from '@contexts';
import { paymentMethodChangerUtils } from './paymentMethodChangerUtils';
import { useLoadingContext } from '../../App';
import { QueryParams } from '../../utils';
import { PaymentMethodLogoInfo } from './LogoConstants';
import { INTERNAL_PAYMENT_METHOD_NAMES } from './InternalPaymentMethodNamesConstants';

export interface AvailablePaymentMethod {
  shortCode: string;
  name: string;
  methodGroupId: number;
  logo: PaymentMethodLogoInfo[string];
  providerCode?: string;
  internalName?: React.ReactNode;
}

interface PaymentMethodChangerProps {
  clientSideData?: {
    chosenPaymentMethod: AvailablePaymentMethod;
    availablePaymentMethods: AvailablePaymentMethod[];
  };
  queryParams: QueryParams;
}

const PaymentMethodChanger = ({ clientSideData, queryParams }: PaymentMethodChangerProps) => {
  const { t } = useTranslation(['npp']);
  const location = useLocation();
  const navigate = useNavigate();
  const { clearErrorSummary } = useErrorSummaryContext();

  const {
    status,
    nextChangeAllowed,
    chosenPaymentMethod,
    availablePaymentMethods,
    forcePaymentMethodChangerDisabled,
    setPaymentMethod,
  } = usePaymentMethodContext();
  const { isLoading } = useLoadingContext();

  const paymentMethodData = useMemo(() => {
    if (clientSideData) {
      return clientSideData;
    }

    return {
      chosenPaymentMethod,
      availablePaymentMethods,
    };
  }, [clientSideData, availablePaymentMethods, chosenPaymentMethod]);

  const selectingPaymentMethod = location.pathname === '/payment';
  const isDisabled =
    isLoading || status === PaymentMethodChangeStatus.Loading || !nextChangeAllowed;

  const onSelect = (paymentMethod: AvailablePaymentMethod) => {
    if (forcePaymentMethodChangerDisabled || isDisabled) {
      return;
    }

    // Prevent clicking on itself
    if (paymentMethodData.chosenPaymentMethod?.methodGroupId === paymentMethod.methodGroupId) {
      return;
    }

    onPaymentMethodChange(paymentMethod);
  };

  const onPaymentMethodChange = (paymentMethod: AvailablePaymentMethod) => {
    setPaymentMethod(paymentMethod);
    clearErrorSummary();
  };

  const getPaymentMethodTitle = (paymentMethod: AvailablePaymentMethod) => {
    return t(
      INTERNAL_PAYMENT_METHOD_NAMES[paymentMethod.shortCode.toLowerCase()]?.translationKey || '',
      paymentMethod.name
    );
  };

  useEffect(() => {
    if (chosenPaymentMethod && !nextChangeAllowed) {
      const validUrlForPM = paymentMethodChangerUtils.generateUrlForPaymentMethod(
        queryParams,
        chosenPaymentMethod
      );

      if (
        !paymentMethodChangerUtils.isPathCorrectForPaymentMethod(
          location.pathname,
          chosenPaymentMethod
        )
      ) {
        navigate(validUrlForPM, { replace: true });
      }
    }
  }, [chosenPaymentMethod, location, navigate, nextChangeAllowed, queryParams]);

  return (
    <S.Root>
      <h4>
        {selectingPaymentMethod
          ? t('npp:paymentMethodChanger.cta')
          : t('npp:paymentMethodChanger.title')}
      </h4>

      <S.PaymentMethodList className="row">
        {paymentMethodData.availablePaymentMethods.map((pm) => {
          const isPaymentMethodDisabled =
            forcePaymentMethodChangerDisabled ||
            (isDisabled &&
              pm.methodGroupId !== paymentMethodData.chosenPaymentMethod?.methodGroupId);

          return (
            <div className="col-4" key={pm.shortCode} title={getPaymentMethodTitle(pm)}>
              <S.PaymentMethodSegment
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSelect(pm);
                  }
                }}
                selectable
                tabIndex={isPaymentMethodDisabled ? undefined : 0}
                selected={paymentMethodData.chosenPaymentMethod?.shortCode === pm.shortCode}
                onClick={() => onSelect(pm)}
                disabled={isPaymentMethodDisabled}
                data-test-id={`pm-${pm.shortCode}`}
              >
                {pm.logo.isExternal ? (
                  <S.PaymentMethodLogo>{pm.logo.img}</S.PaymentMethodLogo>
                ) : (
                  <S.InternalPaymentMethodWrapper>
                    <S.InternalPaymentMethodLogo>{pm.logo.img}</S.InternalPaymentMethodLogo>
                    <S.InternalPaymentMethodText>{pm.internalName}</S.InternalPaymentMethodText>
                  </S.InternalPaymentMethodWrapper>
                )}
              </S.PaymentMethodSegment>
            </div>
          );
        })}
      </S.PaymentMethodList>
      {!nextChangeAllowed && paymentMethodData.availablePaymentMethods.length > 1 && (
        <S.PaymentMethodChangerMessage
          variant="warning"
          header={t('npp:paymentMethodChanger.blockedTitle')}
        >
          {t('npp:paymentMethodChanger.blockedMax')}
        </S.PaymentMethodChangerMessage>
      )}
    </S.Root>
  );
};

export default PaymentMethodChanger;
