import * as S from './ErrorSummaryMessage.styles';
import { FiAlertCircle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useErrorSummaryContext } from '@contexts';

const ErrorSummaryMessage = () => {
  const { errorsNumber, scrollToSummaryBox } = useErrorSummaryContext();
  const { t } = useTranslation(['npp']);

  return (
    <S.Root
      onClick={scrollToSummaryBox}
      icon={<FiAlertCircle size={24} />}
      variant="error"
      header={t('npp:general.errorSummary.messageTitle')}
    >
      {errorsNumber > 1
        ? t('npp:general.errorSummary.messageText_other', {
            count: errorsNumber,
          })
        : t('npp:general.errorSummary.messageText_one', {
            count: errorsNumber,
          })}
    </S.Root>
  );
};

export default ErrorSummaryMessage;
