import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QueryParams } from '../utils';
import i18next from 'i18next';

export function useQueryParams(): QueryParams {
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    return new QueryParams(searchParams);
  }, [searchParams]);
}

export const useReferredState = <T>(
  initialValue: T
): [T, React.MutableRefObject<T>, React.Dispatch<T>] => {
  const [state, setState] = useState<T>(initialValue);
  const reference = useRef<T>(state);

  const setReferredState = (value: T) => {
    reference.current = value;
    setState(value);
  };

  return [state, reference, setReferredState];
};

export const useEnterButtonEventListner = (func: () => void) => {
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        func();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [func]);
};

export const useTabActive = () => {
  const [visibilityState, setVisibilityState] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setVisibilityState(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return visibilityState;
};

export const useDirection = () => {
  return i18next.dir();
};
