/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  CustomizationData,
  AdditionalSettings,
  paymentClient,
  PaymentStatusData,
  SupportContactInformationRecord,
} from '../../../../client/base';
import { useBoolean } from '@britishcouncil/react-common';
import { checkIfImageOrDefault, goToExpiredPage, goToUrl } from '../../../../utils/utils';
import { GoogleAnalyticsHelpers } from '../../../../googleAnalytics/GoogleAnalyticsHelpers';
import { setAxiosHeaders } from '../../../../utils/auditInterceptorsAxiosConfig';
import { EolChat } from '../../EolChat';
import { HeaderBar, ProductsSummaryProps, TimerSectionProps } from '../../../../shared';
import { useTranslation } from 'react-i18next';
import { configureTrackingProps, EntityType, logError } from '../../../../utils/logger-utils';
import { startAppInsightTracking } from '../../applicatonInsights';
import { Footer, FooterLinkTexts, Header } from '@britishcouncil/react-extro-ui';
import i18next, {
  getTranslationSettingsForHeader,
  initializeTranslationForWrapper,
} from '../../../../utils/i18n';
import CommonLayout from '../../../../shared/layout/commonLayout/CommonLayout';
import CommonPaymentSideSection from '../../../../shared/layout/sideSection/payment/CommonPaymentSideSection';
import { useSearchParams, Outlet, useLocation } from 'react-router-dom';
import * as S from './paymentWrappers.styles';
import { useLoadingContext } from '../../../../App';
import PaymentMethodChanger from '../../../../shared/paymentMethodChanger/PaymentMethodChanger';
import { ErrorMsg, PageTitle, SafePaymentsBadge, SupportSection } from '../../..';
import { PageType } from '../types';
import {
  getPaymentStatus,
  getSupportContactInformation,
} from '../../../../shared/PaymentContextClient';
import { PAYMENT_IDENTIFIER_NAME } from '../../../../utils';
import {
  PaymentMethodChangeStatus,
  usePaymentMethodContext,
  PaymentWrapperContext,
  useErrorSummaryContext,
  ShowConfirmationModalParams,
} from '@contexts';
import ErrorSummaryBox from '../../../../shared/errorSummary/ErrorSummaryBox';
import { useNavigateToErrorPage, useQueryParams } from '../../../../hooks';
import { PaymentErrorPagePath } from '../../../../routes/PathConsts';

interface Props {
  options?: HeaderAndFooterOptions;
}

interface HeaderAndFooterOptions {
  withTimer?: boolean;
  isTimerHidden?: boolean;
  withRedirectOnFinishedPayment?: boolean;
  isErrorPage?: boolean;
  withBackButton?: boolean;
  withSideSection?: boolean;
  customPaymentIdParam?: string;
  withPaymentMethodChanger?: boolean;
  withTitle?: boolean;
  pageType?: PageType;
  withSafePaymentsBadge?: boolean;
  alignCenter?: boolean;
  autoTriggerPaymentMethodChange?: boolean;
  /** It works only if propetry 'withBackButton' is set to true*/
  customBackButtonConfirmationModalFunc?: () => ShowConfirmationModalParams;
}

const PaymentWrapper = ({
  options = {
    withTimer: false,
    isTimerHidden: false,
    withRedirectOnFinishedPayment: false,
    isErrorPage: false,
    withBackButton: false,
    withSideSection: false,
    customPaymentIdParam: undefined,
    withPaymentMethodChanger: false,
    withTitle: false,
    withSafePaymentsBadge: false,
    pageType: PageType.Payment,
    alignCenter: false,
    autoTriggerPaymentMethodChange: false,
    customBackButtonConfirmationModalFunc: undefined,
  },
}: Props) => {
  const { isLoading, setIsLoading } = useLoadingContext();
  const [customizationData, setCustomizationData] = useState<CustomizationData>({});
  const [isActive, setIsActive] = useBoolean(false);
  const [isCollectingBillingDetails, setIsCollectingBillingDetails] = useBoolean();
  const [isTranslationAllowed, setIsTranslationAllowed] = useBoolean();
  const [additionalSettings, setAdditionalSettings] = useState<AdditionalSettings>();
  const [getStatusResponse, setGetStatusResponse] = useState<PaymentStatusData>();
  const [timeoutDate, setTimeoutDate] = useState<Date>();
  const [isTimeoutExpired, setIsTimeoutExpired] = useState<boolean>(false);
  const [productsSummary, setProductsSummary] = useState<ProductsSummaryProps>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [supportInfo, setSupportInfo] = useState<SupportContactInformationRecord>();
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation(['npp']);
  const location = useLocation();
  const { navigateToPaymentErrorPage } = useNavigateToErrorPage();
  const {
    status,
    chosenPaymentMethod,
    paymentMethodContextInitialized,
    triggerPaymentMethodChange,
    setPaymentMethodChanged,
    initializePaymentMethodContext,
  } = usePaymentMethodContext();
  const { isErrorSummaryBoxVisible } = useErrorSummaryContext();
  const queryParams = useQueryParams();
  const paymentId = options.customPaymentIdParam
    ? queryParams.getParam(options.customPaymentIdParam)
    : queryParams.getPaymentId();

  const rewritePaymentIdQueryParam = !!options.customPaymentIdParam;

  useEffect(() => {
    setErrorMessage(undefined);
  }, [chosenPaymentMethod]);

  useEffect(() => {
    if (rewritePaymentIdQueryParam) {
      setSearchParams((searchParams) => {
        searchParams.set(PAYMENT_IDENTIFIER_NAME, paymentId);
        return searchParams;
      });
    }
  }, [paymentId, rewritePaymentIdQueryParam, setSearchParams]);

  useEffect(() => {
    if (paymentId)
      getSupportContactInformation(paymentId).then((result) => {
        if (result) {
          setSupportInfo(result);
        }
      });
  }, [paymentId]);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      if (!paymentId) {
        goToUrl(PaymentErrorPagePath);
      }

      localStorage.setItem(PAYMENT_IDENTIFIER_NAME, paymentId);
      try {
        const configurationData = (await paymentClient.getPaymentConfiguration(paymentId)).data;

        configureTrackingProps(
          configurationData.paymentId,
          EntityType.payment,
          configurationData.correlationSessionId!
        );

        startAppInsightTracking();

        if (configurationData.additionalSettings) {
          setAdditionalSettings(configurationData.additionalSettings);
        }

        setAxiosHeaders(configurationData.correlationSessionId!);
        setCustomizationData(configurationData.customizationData!);
        setIsCollectingBillingDetails(configurationData.collectBillingDetails);
        setIsTranslationAllowed(configurationData.isTranslationAllowed);
        initializeTranslationForWrapper(paymentId, configurationData.customizationData);
        options.withTimer && setTimeoutDate(new Date(Date.now() + configurationData.timeoutValue));
      } catch (err) {
        navigateToPaymentErrorPage({ exception: err });
      } finally {
        setIsLoading(false);
      }

      try {
        if (!options.withRedirectOnFinishedPayment) {
          return;
        }

        const status = await getPaymentStatus(paymentId);
        if (status.data.isFinished) {
          window.location.href = status.data.redirectUrl;
          return;
        }

        if (status.data.productsSummary) {
          setProductsSummary({
            items: status.data.productsSummary.lineItems.map((x) => {
              return {
                label: x.description,
                amount: x.amountFormatted,
              };
            }),
            orderId: status.data.correlationId,
            total: status.data.productsSummary.amountFormattedWithCurrency,
          });
        }

        setGetStatusResponse(status.data);
      } catch (err) {
        navigateToPaymentErrorPage({ exception: err });
      } finally {
        setIsActive();
      }
    };

    const handleErrorPage = async () => {
      try {
        let customizationData: CustomizationData | undefined = undefined;
        if (paymentId) {
          const configurationData = (await paymentClient.getPaymentConfiguration(paymentId)).data;
          customizationData = configurationData.customizationData;
        }
        initializeTranslationForWrapper(paymentId, customizationData);
      } catch (e) {
        logError(e);
      }
      setIsActive();
    };

    options.isErrorPage ? handleErrorPage() : loadData();
  }, [paymentId]);

  useEffect(() => {
    const timeoutPayment = () => {
      paymentClient
        .timeoutPayment(paymentId)
        .then(() => {
          GoogleAnalyticsHelpers.UpdateDataLayer({
            category: 'Payment',
            action: 'Timeout',
          });
          goToExpiredPage();
        })
        .catch((err) => {
          navigateToPaymentErrorPage({ exception: err });
        });
    };
    isTimeoutExpired && !isLoading && timeoutPayment();
  }, [isTimeoutExpired, isLoading, paymentId]);

  // Initialize payment method context
  useEffect(() => {
    if (paymentMethodContextInitialized || !getStatusResponse || !paymentId || !location) {
      return;
    }

    initializePaymentMethodContext({ paymentId, queryParams, getStatusResponse, location });
  }, [getStatusResponse, paymentId, paymentMethodContextInitialized, location]);

  useEffect(() => {
    if (
      options.autoTriggerPaymentMethodChange &&
      status === PaymentMethodChangeStatus.Init &&
      paymentMethodContextInitialized
    ) {
      triggerPaymentMethodChange();
    } else if (!options.autoTriggerPaymentMethodChange) {
      setPaymentMethodChanged();
    }
  }, [options.autoTriggerPaymentMethodChange, status, paymentMethodContextInitialized]);

  const linkCustomTexts: FooterLinkTexts = {
    accessibility: t('npp:general.footer.accessibility'),
    dataProtection: t('npp:general.footer.dataProtection'),
    privacyAndCookies: t('npp:general.footer.privacyAndCookies'),
    termsOfUse: t('npp:general.footer.termsOfUse'),
  };

  const timerOptions: TimerSectionProps | undefined =
    options.withTimer && timeoutDate
      ? {
          hidden: options.isTimerHidden,
          timeoutDate,
          onComplete: () => setIsTimeoutExpired(true),
        }
      : undefined;

  const getPageTitle = () => {
    switch (options.pageType) {
      case PageType.Status:
        return t('npp:paymentStatusPage.title');

      case PageType.Payment:
      default:
        return t('npp:general.payOnline');
    }
  };

  if (!isActive) {
    return null;
  }

  return (
    <S.Root>
      <Header
        slogan={t('npp:general.checkout')}
        translations={getTranslationSettingsForHeader(
          paymentId,
          isTranslationAllowed,
          customizationData
        )}
        logoUrl={checkIfImageOrDefault(customizationData.paymentPageLogoImageUrl)}
      />
      <HeaderBar
        withBackButton={options.withBackButton}
        customBackButtonConfirmationModalFunc={options.customBackButtonConfirmationModalFunc}
        entity={{ type: 'Payment', id: paymentId }}
      />

      <CommonLayout
        key={i18next.language}
        alignCenter={options.alignCenter}
        topSection={
          <>
            {isErrorSummaryBoxVisible && <ErrorSummaryBox />}
            {errorMessage && <ErrorMsg content={errorMessage} />}
            {options.withTitle && <PageTitle title={getPageTitle()} />}
          </>
        }
        mainSection={
          <>
            {options.withPaymentMethodChanger && <PaymentMethodChanger queryParams={queryParams} />}
            <Outlet
              context={
                {
                  paymentId,
                  queryParams,
                  isLoading,
                  isCollectingBillingDetails,
                  setIsLoading,
                  onErrorMessageChange: setErrorMessage,
                } satisfies PaymentWrapperContext
              }
            />
            {options.withSafePaymentsBadge && (
              <SafePaymentsBadge
                logoUrl={checkIfImageOrDefault(customizationData.paymentPageLogoImageUrl)}
              />
            )}
            {supportInfo && (
              <SupportSection
                supportInfo={supportInfo.supportContactInformation}
                correlationId={supportInfo.correlationId}
              />
            )}
          </>
        }
        sideSection={
          options.withSideSection ? (
            <CommonPaymentSideSection timer={timerOptions} productSummary={productsSummary} />
          ) : null
        }
      />

      {additionalSettings?.isSalesForceChatEnabled ? (
        <EolChat
          eolChatDeploymentId={additionalSettings.salesForceChatDeploymentId!}
          eolChatOrganisationId={additionalSettings.salesForceChatOrganisationId!}
          eolApiEndpointId={additionalSettings.salesForceApiEndpointId!}
          eolChatUserId={additionalSettings.salesForceChatUserId!}
        />
      ) : null}

      <Footer
        linkUrls={{
          accessibility: customizationData.accessibilityUrl,
          dataProtection: customizationData.dataProtectionUrl,
          privacyAndCookies: customizationData.privacyAndCookiesUrl,
          termsOfUse: customizationData.termsOfUseUrl,
        }}
        copyrightOwner={customizationData.copyrightOwner}
        linkCustomTexts={linkCustomTexts}
      />
    </S.Root>
  );
};

export default PaymentWrapper;
