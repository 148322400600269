import React from 'react';
import { useTranslation } from 'react-i18next';

export interface PaymentMethodNameInfo {
  [key: string]: {
    name: string;
    translationKey: string;
  };
}

export const INTERNAL_PAYMENT_METHOD_NAMES: PaymentMethodNameInfo = {
  card: {
    name: 'Card',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.card',
  },
  banktransfer: {
    name: 'Bank Transfer',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.bankTransfer',
  },
  onlinebankingindia: {
    name: 'Online Banking India',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.onlineBankingIndia',
  },
  walletsindia: {
    name: 'Wallets India',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.walletsIndia',
  },
  mobilemoney: {
    name: 'Mobile Money',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.mobileMoney',
  },
  mobilebanking: {
    name: 'Mobile Banking',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.mobileBanking',
  },
  netbanking: {
    name: 'Net Banking',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.netBanking',
  },
  atmcard: {
    name: 'ATM Card',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.atmCard',
  },
  internationalcard: {
    name: 'International Card',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.internationalCard',
  },
  qrcode: {
    name: 'QR Code',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.qrCode',
  },
  paybybank: {
    name: 'Pay by Bank',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.payByBank',
  },
  fiuubanktransfers: {
    name: 'FIUU Bank Transfers',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.fiuuBankTransfers',
  },
  syncbanktransfer: {
    name: 'Sync Bank Transfer',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.syncBankTransfer',
  },
  paydollar: {
    name: 'Paydollar',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.paydollar',
  },
  paydollarcard: {
    name: 'Paydollar Card',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.paydollarcard',
  },
  doku: {
    name: 'Card or e-Wallet',
    translationKey: 'npp:paymentMethodChanger.paymentMethodsName.doku',
  },
};

interface InternalPaymentMethodNameProps {
  method: string;
}

const getInternalPaymentMethodNameWithDefault = (method: string): PaymentMethodNameInfo[string] => {
  const normalizedMethod = method.toLowerCase();
  return INTERNAL_PAYMENT_METHOD_NAMES[normalizedMethod] || INTERNAL_PAYMENT_METHOD_NAMES.card;
};

export const InternalPaymentMethodName = ({ method }: InternalPaymentMethodNameProps): JSX.Element => {
  const { t } = useTranslation();
  const paymentMethod = getInternalPaymentMethodNameWithDefault(method);
  return <>{t(paymentMethod.translationKey, paymentMethod.name)}</>;
};

export const getInternalPaymentMethodName = (method: string): React.ReactNode => {
  return <InternalPaymentMethodName method={method} />;
};
