import styled from '@emotion/styled';
import { ErrorAlert, NotificationPanel } from '../shared';

interface Props {
  id?: string;
  title?: string;
  subtitle?: string;
  hasError?: boolean;
  notificationText?: string;
}

const StyledTitle = styled.div`
  h1 {
    margin-bottom: 20px;
    padding-right: 0;
  }
`;

const HeaderDivider = styled.div`
  display: block;
  width: 45px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.indigo};
  margin-bottom: 20px;
`;

const HeaderContainer = styled.div`
  div {
    @media (min-width: 850px) {
      padding-left: 0 !important;
    }
  }
`;

export const PaymentPageHeader = (props: Props) => (
  <>
    {props.notificationText && (
      <NotificationPanel text={props.notificationText} data-clarity-unmask="true" />
    )}
    {props.hasError && <ErrorAlert />}
    <HeaderContainer>
      <div className="row" data-clarity-unmask="true">
        <div className="col-xs-12 col-md-12">
          <StyledTitle className="page-header">
            <h1 id={props.id}>{props.title}</h1>
          </StyledTitle>
          {props.subtitle && <h2 className="margin-bottom-10">{props.subtitle}</h2>}
          <HeaderDivider />
        </div>
      </div>
    </HeaderContainer>
  </>
);
