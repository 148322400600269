import { useEffect, useState } from 'react';
import { loadScript, throwScriptLoadError } from './scriptLoader';
import './EolChat.scss';
import classNames from 'classnames';
import { logError } from '../../utils/logger-utils';

interface Props {
  eolChatDeploymentId: string;
  eolChatOrganisationId: string;
  eolApiEndpointId: string;
  eolChatUserId: string;
}

export const EolChat = (props: Props) => {
  const scriptUrl = 'https://c.la1-c2-lo2.salesforceliveagent.com/content/g/js/50.0/deployment.js';
  const windowObject = window as any;
  const onlineButtonId = `liveagent-button-online_${props.eolChatUserId}}`;
  const offlineButtonId = `liveagent-button-offline_${props.eolChatUserId}}`;
  const [isOfflineInstructionOpen, setIsOfflineInstructionOpen] = useState(false);
  const [isChatInitialized, setIsChatInitialized] = useState(false);

  useEffect(() => {
    const loadEolChat = () => {
      loadScript({
        src: scriptUrl,
        async: false,
        loadTo: 'body',
        useNonce: true,
      })
        .then((result) => {
          if (result) {
            initChat();
          } else {
            throwScriptLoadError({
              scriptUrl: scriptUrl,
              providerName: 'EolChat',
            });
          }
        })
        .catch((err) => {
          logError(err);
        });
    };

    const initChat = () => {
      windowObject.liveagent.init(
        `https://${props.eolApiEndpointId}.salesforceliveagent.com/chat`,
        props.eolChatDeploymentId,
        props.eolChatOrganisationId
      );

      if (!windowObject._laq) {
        windowObject._laq = [];
      }

      windowObject._laq.push(function () {
        windowObject.liveagent.showWhenOnline(
          props.eolChatUserId,
          document.getElementById(onlineButtonId)
        );
        windowObject.liveagent.showWhenOffline(
          props.eolChatUserId,
          document.getElementById(offlineButtonId)
        );
      });

      setIsChatInitialized(true);
    };

    loadEolChat();
  }, [
    offlineButtonId,
    onlineButtonId,
    props.eolApiEndpointId,
    props.eolChatDeploymentId,
    props.eolChatOrganisationId,
    props.eolChatUserId,
    windowObject,
    windowObject._laq,
    windowObject.liveagent,
  ]);

  const startChat = () => {
    (window as any).liveagent.startChat(props.eolChatUserId);
  };

  return (
    <>
      <div className="chat-images">
        <button
          className="chat-images-button chat-images-button-online"
          id={onlineButtonId}
          disabled={!isChatInitialized}
          onClick={() => startChat()}
          style={{ display: 'none' }}
        >
          <span className="chat-images-button-icon"></span>
        </button>
        <button
          className="chat-images-button chat-images-button-offline"
          disabled={!isChatInitialized}
          onClick={() => setIsOfflineInstructionOpen(true)}
          id={offlineButtonId}
          style={{ display: 'none' }}
        >
          <span className="chat-images-button-icon"></span>
        </button>

        <div
          id="chat-unavailable"
          className={classNames({
            active: isOfflineInstructionOpen,
          })}
        >
          <span id="chat-unavailable-close" onClick={() => setIsOfflineInstructionOpen(false)}>
            X
          </span>
          Sorry! Live chat is currently unavailable.
          <br />
          Please email us at&nbsp;
          <a href="mailto:support.englishonline@britishcouncil.org" id="chat-unavailable-email">
            support.englishonline@britishcouncil.org
          </a>
        </div>
      </div>
    </>
  );
};
