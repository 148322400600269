import React, { useCallback, useMemo, useState } from 'react';
import { AvailablePaymentMethod, paymentMethodChangerUtils } from '../../components';
import { paymentClient } from '../../client/base';
import { getPaymentMethodLogoOrDefault } from '../../shared/paymentMethodChanger/LogoConstants';
import { getInternalPaymentMethodName } from '../../shared/paymentMethodChanger/InternalPaymentMethodNamesConstants';
import { useNavigate } from 'react-router-dom';
import { logError } from '../../utils/logger-utils';
import { goToUrl, QueryParams } from '../../utils';
import {
  InitializePaymentMethodContextModel,
  PaymentMethodChangeStatus,
  PaymentMethodContextType,
} from './PaymentMethodContextTypes';
import { AxiosError, HttpStatusCode } from 'axios';
import { getPaymentStatus } from '../../shared/PaymentContextClient';

const PaymentMethodContext = React.createContext<PaymentMethodContextType | null>(null);

const PaymentMethodContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [paymentMethodContextInitialized, setPaymentMethodContextInitialized] =
    useState<boolean>(false);
  const [forcePaymentMethodChangerDisabled, setForcePaymentMethodChangerDisabled] = useState(false);
  const [status, setStatus] = useState(PaymentMethodChangeStatus.Init);
  const [paymentId, setPaymentId] = useState<string | undefined>();
  const [queryParams, setQueryParams] = useState<QueryParams>(
    new QueryParams(new URLSearchParams())
  );
  const [nextChangeAllowed, setNextChangeAllowed] = useState<boolean>(true);
  const [chosenPaymentMethod, setChosenPaymentMethod] = useState<
    AvailablePaymentMethod | undefined
  >();
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<AvailablePaymentMethod[]>(
    []
  );
  const navigate = useNavigate();

  const setPaymentMethod = useCallback(
    (paymentMethod: AvailablePaymentMethod) => {
      setChosenPaymentMethod(paymentMethod);
      setStatus(PaymentMethodChangeStatus.Init);
      navigate(paymentMethodChangerUtils.generateUrlForPaymentMethod(queryParams, paymentMethod), {
        replace: true,
      });
    },
    [navigate, queryParams]
  );

  const setPaymentMethodChanged = () => {
    setStatus(PaymentMethodChangeStatus.Success);
  };

  const initializePaymentMethodContext = useCallback(
    async ({
      getStatusResponse,
      location,
      paymentId,
      queryParams,
    }: InitializePaymentMethodContextModel) => {
      setPaymentId(paymentId);
      setQueryParams(queryParams);
      const activeConfigurations = (await paymentClient.getPaymentActiveConfigurations(paymentId))
        .data;

        const availablePMs = activeConfigurations.map((ac) => ({
          name: ac.name,
          shortCode: ac.shortCode,
          methodGroupId: ac.providerPaymentMethodGroupId,
          providerCode: ac.providerCode,
          logo: getPaymentMethodLogoOrDefault(ac.shortCode),
          internalName: getInternalPaymentMethodName(ac.shortCode),
        }));
      setAvailablePaymentMethods(availablePMs);

      const paymentMethodFromUrl = paymentMethodChangerUtils.getPaymentMethodByPath(
        location.pathname,
        availablePMs
      );

      const paymentMethodFromGetStatus = availablePMs.find(
        (x) =>
          x.providerCode === getStatusResponse.providerCode &&
          x.shortCode === getStatusResponse.providerPaymentMethodGroupCode
      );

      const paymentMethod = paymentMethodFromUrl?.paymentMethod ?? paymentMethodFromGetStatus;

      // If no payment method is present we are on the decision page - get status and url both have no PM embeeded in it
      if (!paymentMethod) {
        setPaymentMethodContextInitialized(true);
        return;
      }

      // 1. Set active payment method to payment method from url or from get status (in this specific order)
      setChosenPaymentMethod(paymentMethod);
      setStatus(PaymentMethodChangeStatus.Init);
      setPaymentMethodContextInitialized(true);

      // 2. Navigate if needed
      if (paymentMethod.methodGroupId !== paymentMethodFromUrl?.paymentMethod.methodGroupId) {
        navigate(
          paymentMethodChangerUtils.generateUrlForPaymentMethod(queryParams, paymentMethod),
          {
            replace: paymentMethodFromUrl === undefined,
          }
        );
      }
    },
    [navigate]
  );

  const triggerPaymentMethodChange = useCallback(async () => {
    if (!chosenPaymentMethod || !nextChangeAllowed) {
      return;
    }

    if (
      status === PaymentMethodChangeStatus.Loading ||
      status === PaymentMethodChangeStatus.Success
    ) {
      return;
    }
    setStatus(PaymentMethodChangeStatus.Loading);

    try {
      const providerPaymentMethodGroupId = Number(chosenPaymentMethod.methodGroupId);
      const choosePaymentMethodResult = (
        await paymentClient.choosePaymentMethod({
          paymentId: paymentId,
          providerPaymentMethodGroupId,
        })
      ).data;

      if (choosePaymentMethodResult.isPaymentFinished) {
        return goToUrl(choosePaymentMethodResult.redirectUrl);
      }

      setNextChangeAllowed(choosePaymentMethodResult.ifNextChangeIsPossible);
      setStatus(PaymentMethodChangeStatus.Success);
    } catch (error) {
      if (!paymentId) {
        logError(error);
        setStatus(PaymentMethodChangeStatus.Failed);
        return;
      }

      const axiosError = error as AxiosError;
      if (axiosError.response?.status === HttpStatusCode.UnprocessableEntity) {
        const paymentStatus = (await getPaymentStatus(paymentId)).data;
        const pmFromStatus = availablePaymentMethods.find(
          (x) =>
            x.providerCode === paymentStatus.providerCode &&
            x.shortCode === paymentStatus.providerPaymentMethodGroupCode
        );

        if (pmFromStatus) {
          setPaymentMethod(pmFromStatus);
        } else {
          logError(error);
          setStatus(PaymentMethodChangeStatus.Failed);
        }
      } else {
        logError(error);
        setStatus(PaymentMethodChangeStatus.Failed);
      }
    }
  }, [
    availablePaymentMethods,
    chosenPaymentMethod,
    nextChangeAllowed,
    paymentId,
    setPaymentMethod,
    status,
  ]);

  const contextValue = useMemo(() => {
    return {
      status,
      nextChangeAllowed,
      chosenPaymentMethod,
      availablePaymentMethods,
      paymentMethodContextInitialized,
      forcePaymentMethodChangerDisabled,
      setPaymentMethod,
      triggerPaymentMethodChange,
      setPaymentMethodChanged,
      initializePaymentMethodContext,
      setForcePaymentMethodChangerDisabled,
    };
  }, [
    status,
    nextChangeAllowed,
    chosenPaymentMethod,
    availablePaymentMethods,
    paymentMethodContextInitialized,
    forcePaymentMethodChangerDisabled,
    setPaymentMethod,
    triggerPaymentMethodChange,
    initializePaymentMethodContext,
    setForcePaymentMethodChangerDisabled,
  ]);

  return (
    <PaymentMethodContext.Provider value={contextValue}>{children}</PaymentMethodContext.Provider>
  );
};

export { PaymentMethodContext, PaymentMethodContextProvider };
