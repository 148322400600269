/* eslint-disable no-console */
import TagManager from 'react-gtm-module-nonce';
import { DataLayerClient } from '../client/api-google-analytics';
import {
  payServiceClient,
  PaymentInformation,
  SubscriptionInformation,
  InstalmentPlanInformation,
  StripeInstalmentPlanFormData,
} from '../client/base';
import { nonceTemplate } from '../nonce';

const clientAppDataLayer: DataLayer[] = [];

export class GoogleAnalyticsHelpers {
  static InitializeTagManagerAsync = async () => {
    await payServiceClient
      .getGoogleAnalyticsSettings()
      .then((response) => {
        const data = response.data;
        TagManager.initialize({
          gtmId: data.gtmCode,
          nonce: nonceTemplate,
        });
      })
      .catch((err) => console.error('GoogleAnalyticsSettingsError', err.message));
  };

  static InitializeTagManagerGaAndSendPageViewAsync = async (correlationId?: string) => {
    await payServiceClient
      .getGoogleAnalyticsSettings()
      .then((response) => {
        const data = response.data;
        TagManager.initialize({
          gtmId: data.gtmCode,
          nonce: nonceTemplate,
        });

        if (correlationId) {
          localStorage.setItem('correlationId', correlationId);
        } else {
          const correlationIdFromLS = localStorage.getItem('correlationId');
          correlationId = correlationIdFromLS ? correlationIdFromLS : undefined;
        }

        const clientId = GoogleAnalyticsHelpers.getCookie('_ga');

        if (clientId) {
          new DataLayerClient().get(clientId).then((response) => {
            if (response.data.dataLayer) {
              const dataLayer = JSON.parse(response.data.dataLayer);
              clientAppDataLayer.push(...dataLayer);

              const dataLayerCorrelationId = clientAppDataLayer.find((x) =>
                x.event.includes('dataLayerCorrelationId')
              );
              if (
                dataLayerCorrelationId &&
                JSON.parse(dataLayerCorrelationId.event).dataLayerCorrelationId === correlationId
              ) {
                for (let i = 0; i < dataLayer.length; i++) {
                  const gtmDataLayer = {
                    dataLayer: JSON.parse(dataLayer[i].event),
                    dataLayerName: 'dataLayer',
                  };
                  TagManager.dataLayer(gtmDataLayer);
                }
              }
            }
          });
        }

        GoogleAnalyticsHelpers.UpdateDataLayer({
          event: 'pageview',
          path: window.location.pathname,
        });
      })
      .catch((err) => console.error('GoogleAnalyticsSettingsError', err.message));
  };

  static getCookie(name: string): string {
    const nameLenPlus = name.length + 1;
    return (
      document.cookie
        .split(';')
        .map((c) => c.trim())
        .filter((cookie) => {
          return cookie.startsWith(`${name}=`);
        })
        .map((cookie) => {
          return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || ''
    );
  }

  static getClientAppDataLayer() {
    return clientAppDataLayer;
  }

  static UpdateDataLayerForPayment = (paymentInformation?: PaymentInformation | null) => {
    if (!paymentInformation) {
      return;
    }
    const dataLayer = {
      countryCode: paymentInformation.countryCode,
      operationCode: paymentInformation.operationCode,
      applicationCode: paymentInformation.operationCode,
      providerConfiguration: paymentInformation.providerConfigurationCode,
      correlationId: paymentInformation.correlationId,
      amount: paymentInformation.amount,
      currency: paymentInformation.currency,
      paymentId: paymentInformation.paymentId,
      paymentMethodGroup: paymentInformation.paymentMethodGroup,
    };

    GoogleAnalyticsHelpers.UpdateDataLayer(dataLayer);
  };

  static UpdateDataLayerForSubscription = (subscriptionInformation: SubscriptionInformation) => {
    const dataLayer = {
      countryCode: subscriptionInformation.countryCode,
      operationCode: subscriptionInformation.operationCode,
      providerConfiguration: subscriptionInformation.providerConfigurationCode,
      correlationId: subscriptionInformation.correlationId,
      amount: subscriptionInformation.amount,
      currency: subscriptionInformation.currency,
      subscriptionId: subscriptionInformation.subscriptionId,
      paymentMethodGroup: subscriptionInformation.paymentMethodGroup,
      planId: subscriptionInformation.planId,
      productName: subscriptionInformation.productName,
      productDescription: subscriptionInformation.productDescription,
      intervalUnit: subscriptionInformation.intervalUnit,
      intervalCount: subscriptionInformation.intervalCount,
      trialIntervalUnit: subscriptionInformation.trialIntervalUnit,
      trialIntervalCount: subscriptionInformation.trialIntervalCount,
    };

    GoogleAnalyticsHelpers.UpdateDataLayer(dataLayer);
  };

  static UpdateDataLayerForInstalmentPlan = (
    instalmentPlan: InstalmentPlanInformation,
    transaction: StripeInstalmentPlanFormData
  ) => {
    const dataLayer = {
      countryCode: instalmentPlan.countryCode,
      operationCode: instalmentPlan.operationCode,
      providerConfiguration: instalmentPlan.providerConfigurationCode,
      correlationId: instalmentPlan.correlationId,
      amount: transaction.firstInstalmentAmount,
      currency: instalmentPlan.currency,
      instalmentPlanId: instalmentPlan.instalmentPlanId,
      paymentMethodGroup: instalmentPlan.paymentMethodGroup,
    };

    GoogleAnalyticsHelpers.UpdateDataLayer(dataLayer);
  };

  static UpdateDataLayerWithStripeCardPaymentFlow = (
    stripeCardPaymentFlow: 'Card' | 'Payment Request Button'
  ) => {
    GoogleAnalyticsHelpers.UpdateDataLayer({ stripeCardPaymentFlow: stripeCardPaymentFlow });
  };

  static UpdateDataLayer = (event: any) => {
    const dataLayerEntry =
      event.category && event.action
        ? { ...event, event: `${event.category} - ${event.action}` }
        : event;

    clientAppDataLayer.push({ event: JSON.stringify(event) });
    TagManager.dataLayer({
      dataLayer: dataLayerEntry,
      dataLayerName: 'dataLayer',
    });
  };

  static RecordGenericPaymentFail = () => {
    GoogleAnalyticsHelpers.UpdateDataLayer({
      category: 'Payment',
      action: 'Payment Attempt Failed',
      label: 'Generic',
    });
  };
}

interface DataLayer {
  event: string;
}
