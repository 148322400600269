import styled from '@emotion/styled';
import { QRCodeCanvas } from 'qrcode.react';
import { isMobile } from 'react-device-detect';
import { Buffer } from 'buffer';
import jpeg from 'jpeg-js';
import jsQR from 'jsqr';
import { useTranslation } from 'react-i18next';
import { ConfirmPaymentButton } from '..';
import SkeletonLoader from '../../shared/SkeletonLoader';

interface QRCodeSectionProps {
  qrCodeImage?: string;
  qrCodeUrl?: string;
}

const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  canvas {
    margin: 20px;
  }
`;
const QRCodeDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSize.desktop.body};
  font-weight: 400;
  line-height: ${({ theme }) => theme.spacing.lg};
`;

const QRCodeSection = ({ qrCodeImage, qrCodeUrl }: QRCodeSectionProps) => {
  const { t } = useTranslation(['npp']);

  if (qrCodeImage) {
    (window as any).global = window;
    window.Buffer = window.Buffer || Buffer;
    const jpegData = Buffer.from(qrCodeImage, 'base64');
    const rawImageData = jpeg.decode(jpegData);
    const imageDataArray = new Uint8ClampedArray(rawImageData.data.length);
    for (let i = 0; i < rawImageData.data.length; i++) {
      imageDataArray[i] = rawImageData.data[i];
    }
    const decodedQR = jsQR(imageDataArray, rawImageData.width, rawImageData.height);
    if (decodedQR && decodedQR.data) {
      qrCodeUrl = decodedQR.data;
    }
  }

  return qrCodeUrl ? (
    <QRCodeContainer>
      <QRCodeCanvas value={qrCodeUrl} size={192} />
      {isMobile && (
        <ConfirmPaymentButton
          id="btn-pay"
          size="default"
          customText={t('npp:payease.buttonText')}
          onClick={() => window.location.assign(qrCodeUrl!)}
        />
      )}
      <QRCodeDescription className="margin-top-20">{t('npp:payease.info')}.</QRCodeDescription>
    </QRCodeContainer>
  ) : (
    <SkeletonLoader />
  );
};

export default QRCodeSection;
