import { useLayoutEffect, useRef } from 'react';
import * as S from './ErrorSummaryBox.styles';
import { useErrorSummaryContext } from '@contexts';
import { useTranslation } from 'react-i18next';

const ErrorSummaryBox = () => {
  const { t } = useTranslation(['npp']);
  const { errors, refCallback } = useErrorSummaryContext();
  const refErrorSummary = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    refCallback(refErrorSummary);
    if (refErrorSummary?.current) {
      const firstError = refErrorSummary.current.getElementsByTagName('button');
      firstError[0]?.focus({ preventScroll: true });
    }
  }, [refCallback, refErrorSummary]);

  const formatErrorMessage = (text: string) => (text.endsWith('.') ? text : `${text}.`);

  return (
    <S.StyledErrorSummaryContainer ref={refErrorSummary} role="alert">
      <S.StyledTitle>{t('npp:general.errorSummary.boxTitle')}</S.StyledTitle>
      <S.StyledErrorLinkContainer>
        {[...errors]
          .sort((error1, error2) => error1.order - error2.order)
          .map((error, index) => (
            <div key={`div-${error.order}`}>
              <S.StyledErrorLink
                key={error.order}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    error.scrollAndFocusOnInput();
                  }
                }}
                onClick={error.scrollAndFocusOnInput}
              >
                {`${index + 1}. `}
                <span>{formatErrorMessage(error.errorMessage)}</span>
              </S.StyledErrorLink>
            </div>
          ))}
      </S.StyledErrorLinkContainer>
    </S.StyledErrorSummaryContainer>
  );
};

export default ErrorSummaryBox;
